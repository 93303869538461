var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-navigation-drawer',{staticClass:"sidebar",attrs:{"absolute":"","top":"","overlay-color":"rgb(0, 0, 0)","overlay-opacity":"0.4"},model:{value:(_vm.$store.state.isVisibleSidebar),callback:function ($$v) {_vm.$set(_vm.$store.state, "isVisibleSidebar", $$v)},expression:"$store.state.isVisibleSidebar"}},[_c('div',{staticClass:"sidebar-logo"},[_c('img',{staticClass:"header__img",attrs:{"src":require("../assets/logo.png"),"alt":"логотип"}})]),_c('v-list',{attrs:{"nav":"","dense":""}},[_c('v-list-item-group',{attrs:{"active-class":"deep-purple--text text--accent-4"},model:{value:(_vm.group),callback:function ($$v) {_vm.group=$$v},expression:"group"}},[(!this.$store.state.isActivetedUser)?_c('v-list-item',{class:{
                        activeItem: _vm.$route.path === '/apruve-email',
                        notActiveItem: _vm.$route.path !== '/apruve-email',
                    },on:{"click":function($event){_vm.$router.push({ name: 'apruve-email' }).catch(() => {})}}},[_c('v-list-item-title',[_vm._v("Подтвердить email")])],1):_vm._e(),(this.$store.state.isActivetedUser)?_c('v-list-item',{class:{
                        activeItem: _vm.$route.path === '/profile',
                        notActiveItem: _vm.$route.path !== '/profile',
                    },attrs:{"aria-selected":"true"},on:{"click":function($event){_vm.$router.push({ name: 'profile' }).catch(() => {})}}},[_c('v-list-item-title',[_vm._v("Мой профиль")])],1):_vm._e(),_c('v-list-item',{class:{
                        activeItem: _vm.$route.path === '/upload',
                        notActiveItem: _vm.$route.path !== '/upload',
                    },attrs:{"aria-selected":"false"},on:{"click":function($event){_vm.$store.state.isNavBarOff
                            ? _vm.$router.push({ name: 'LcUpload' }).catch(() => {})
                            : _vm.$router.push({ name: 'LcScan' }).catch(() => {})}}},[_c('v-list-item-title',[_vm._v("Загрузить новый чек")])],1),_c('v-list-item',{class:{
                        activeItem: _vm.$route.path === '/lc/',
                        notActiveItem: _vm.$route.path !== '/lc/',
                    },attrs:{"aria-selected":"false"},on:{"click":function($event){_vm.$router.push({ name: 'LcMain' }).catch(() => {})}}},[_c('v-list-item-title',[_vm._v("Загруженные чеки")])],1),_c('v-list-item',{class:{
                        activeItem: _vm.$route.path === '/notification',
                        notActiveItem: _vm.$route.path !== '/notification',
                    }},[_c('v-list-item-title',{staticClass:"sidebar-notifications",on:{"click":function($event){_vm.$router.push({ name: 'notifications' }).catch(() => {})}}},[_vm._v("Уведомления "),_c('div',{staticClass:"sidebar-notifications__counter"},[_vm._v(" "+_vm._s(this.$store.state.filteredNotification)+" ")])])],1),_c('v-list-item',{class:{ notActiveItem: _vm.$route.path !== '/support' }},[_c('a',{attrs:{"href":'mailto:support@kalaka-malaka.ru',"target":"_blank"}},[_c('v-list-item-title',[_vm._v("Поддержка")])],1)])],1)],1),_c('div',{staticClass:"sidebar-exitBtn"},[_c('p',{on:{"click":_vm.exit}},[_vm._v("Выйти")])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }