import { render, staticRenderFns } from "./BodyProfile.vue?vue&type=template&id=b5fa744a&scoped=true"
import script from "./BodyProfile.vue?vue&type=script&lang=js"
export * from "./BodyProfile.vue?vue&type=script&lang=js"
import style0 from "./BodyProfile.vue?vue&type=style&index=0&id=b5fa744a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b5fa744a",
  null
  
)

export default component.exports