<!-- eslint-disable vue/require-v-for-key -->
<template>
    <div class="wrapper-profile">
        <v-form class="v-form" ref="form" @submit.prevent>
            <h2>Реквизиты победителя</h2>
            <p class="text">Чтобы мы могли передать ваш приз - заполните анкету с вашими данными</p>
            <div class="inputs">
                <v-text-field
                    dense
                    outlined
                    clearable
                    label="Email"
                    placeholder="example@yandex.ru"
                    v-model="email"
                    :rules="[rules.requiredEmail]"
                    @click="resetValidation"
                />

                <v-text-field
                    dense
                    outlined
                    clearable
                    label="ФИО"
                    placeholder="Иванов Иван Иванович"
                    v-model="fio"
                    :rules="[rules.requiredFio]"
                    @click="resetValidation"
                />

                <v-text-field
                    dense
                    outlined
                    clearable
                    label="Телефон"
                    placeholder="+7 (000) 000-00-00"
                    v-mask="'+7 (###) ###-##-##'"
                    v-model="phone"
                    :rules="[rules.requiredPhone]"
                    @click="resetValidation"
                />

                <v-text-field
                    dense
                    outlined
                    clearable
                    label="ИНН"
                    placeholder="000000000000"
                    maxlength="12"
                    counter
                    v-mask="'############'"
                    v-model="inn"
                    :rules="[rules.requiredInn]"
                    @click="resetValidation"
                />

                <v-text-field
                    dense
                    outlined
                    clearable
                    label="СНИЛС"
                    placeholder="00000000000"
                    maxlength="11"
                    counter
                    v-mask="'###########'"
                    v-model="snils"
                    :rules="[rules.requiredSnils]"
                    @click="resetValidation"
                />

                <p class="gray-text" style="text-align: left; padding-bottom: 20px">
                    Паспортные данные
                </p>

                <v-text-field
                    dense
                    outlined
                    clearable
                    label="Серия и номер"
                    v-mask="'## ## ######'"
                    v-model="serialNumber"
                    :rules="[rules.requireSerialNumberRules]"
                    @click="resetValidation"
                />

                <v-text-field
                    dense
                    outlined
                    clearable
                    label="Когда выдан"
                    v-mask="'##.##.####'"
                    v-model="whenIssued"
                    :rules="[rules.requireWhenIssuedRules]"
                    @click="resetValidation"
                />

                <v-text-field
                    dense
                    outlined
                    clearable
                    label="Кем выдан"
                    v-model="issuedBy"
                    :rules="[rules.requireIssuedByRules]"
                    @click="resetValidation"
                />

                <v-text-field
                    dense
                    outlined
                    clearable
                    label="Адрес регистрации"
                    v-model="registrationAddress"
                    :rules="[rules.requireRegistrationAddressRules]"
                    @click="resetValidation"
                />

                <v-text-field
                    dense
                    outlined
                    clearable
                    label="Адрес доставки приза"
                    v-model="address"
                    :rules="[rules.requiredAddress]"
                    @click="resetValidation"
                />
            </div>

            <p class="gray-text">
                Загрузите копию паспорта <br />2-3 страницы и страница с регистрацией
            </p>

            <v-file-input
                small-chips
                label="Загрузите фото"
                prepend-icon="mdi-camera"
                accept="image/png, image/jpeg, image/jpg"
                @change="updateFiles"
                @click:clear="clearFile"
                :rules="[rules.requireFile]"
            />

            <div v-if="files.length">
                <div class="fileName" v-for="{ name } in files">{{ name }}</div>
            </div>

            <div class="checkbox-wrapper">
                <div class="politic-checkbox">
                    <v-checkbox v-model="politicCheckbox" :rules="[rules.requirePolitic]" />

                    <p class="politic">
                        Я принимаю условия
                        <a href="http://cdn.kalaka-malaka.ru/pdf/agreement.pdf" target="_blank"
                            >пользовательского соглашения</a
                        >
                        и соглашаюсь с<a
                            href="http://cdn.kalaka-malaka.ru/pdf/policy.pdf"
                            target="_blank"
                            >политикой обработки персональных данных</a
                        >
                    </p>
                </div>

                <div class="confirm-checkbox">
                    <v-checkbox
                        v-model="correctUserDataCheckBox"
                        :rules="[rules.requireCorrectUserData]"
                    />
                    <p class="confirm">Я подтверждаю достоверность предоставленных мною данных</p>
                </div>
            </div>

            <v-btn
                block
                depressed
                color="primary"
                class="send-btn"
                type="submit"
                :disabled="false"
                :loading="isLoading"
                @click="sendForm"
            >
                <div class="btn-content-container">Отправить</div>
            </v-btn>
        </v-form>
    </div>
</template>

<script>
import axios from "axios";
import jwt_decode from "jwt-decode";
import { mapActions } from "vuex";

export default {
    data() {
        return {
            userId: "",
            email: "",
            fio: "",
            phone: "",
            inn: "",
            snils: "",
            serialNumber: "",
            whenIssued: "",
            issuedBy: "",
            registrationAddress: "",
            address: "",
            files: [],
            politicCheckbox: false,
            correctUserDataCheckBox: false,
            isLoading: false,
            rules: {
                requiredEmail: this.emailRules,
                requiredFio: this.fioRules,
                requiredPhone: this.phoneRules,
                requiredInn: this.innRules,
                requiredSnils: this.snilsRules,
                requiredAddress: this.addressRules,
                requirePolitic: this.politicRules,
                requireCorrectUserData: this.userDataRules,
                requireFile: this.fileRules,
                requireSerialNumberRules: this.serialNumberRules,
                requireWhenIssuedRules: this.whenIssuedRules,
                requireIssuedByRules: this.issuedByRules,
                requireRegistrationAddressRules: this.registrationAddressRules,
            },
        };
    },
    methods: {
        ...mapActions({
            getCurrentUserIdFromToken: "getCurrentUserIdFromToken",
            getUserData: "getUserData",
        }),
        emailRules() {
            const pattern =
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

            if (!this.email) return "Укажите ваш Email";
            if (!pattern.test(this.email)) return "Неверный формат почты";

            return true;
        },
        fioRules() {
            const pattern = /^[A-я]{2,} [A-я]{2,} [A-я]{2,}$/gm;

            if (!this.fio) return "Укажите ваше ФИО";
            if (!pattern.test(this.fio)) return "Проверьте вводимые данные";

            return true;
        },
        phoneRules() {
            const pattern = /^\+\d \(\d{3,3}\) \d{3,3}-\d{2,2}-\d{2,2}$/gm;

            if (!this.phone) return "Укажите ваш телефон";
            if (!pattern.test(this.phone)) return "Укажите действительный номер телефона";

            return true;
        },
        innRules() {
            if (!this.inn) return "Укажите ваш ИНН";
            if (this.inn.length !== 12) return "НУкажите действительный номер ИНН";

            return true;
        },
        addressRules() {
            if (!this.address) return "Введите адрес доставки";

            return true;
        },
        snilsRules() {
            if (!this.snils) return "Укажите ваш СНИЛС";
            if (this.snils.length !== 11) return "Укажите действительный номер СНИЛС";

            return true;
        },
        politicRules() {
            if (!this.politicCheckbox) return false;
            return true;
        },
        userDataRules() {
            if (!this.correctUserDataCheckBox) return false;
            return true;
        },
        resetValidation() {
            this.$refs.form.resetValidation();
        },
        whenIssuedRules() {
            const pattern = /^\d{2}\.\d{2}\.\d{4}$/gm;

            if (!this.whenIssued) return "Укажите дату выдачи паспорта";
            if (!pattern.test(this.whenIssued)) return "Проверьте вводимые данные";

            return true;
        },
        fileRules() {
            if (this.files.length < 2 || this.files.length > 4) return "минимум 2-3 фото";
            return true;
        },
        serialNumberRules() {
            const pattern = /^\d{2} \d{2} \d{6}$/gm;

            if (!this.serialNumber) return "Введите серию и номер паспорта";
            if (!pattern.test(this.serialNumber)) return "Укажите действительные номер и серию";

            return true;
        },
        issuedByRules() {
            if (!this.issuedBy) return "Введите кем и когда выдан";

            return true;
        },
        registrationAddressRules() {
            if (!this.registrationAddress) return "Введите адрес регистрации";

            return true;
        },
        updateFiles(file) {
            if (!file) return;
            this.files.push(file);
        },
        clearFile() {
            this.files = [];
        },

        async sendForm() {
            try {
                const formData = new FormData();

                const isValid = await this.$refs.form.validate();
                if (!isValid) return;

                formData.append("userId", this.userId);
                formData.append("email", this.email);
                formData.append("fio", this.fio);
                formData.append("phone", this.phone);
                formData.append("inn", this.inn);
                formData.append("snils", this.snils);
                formData.append("serialNumber", this.serialNumber);
                formData.append("whenIssued", this.whenIssued);
                formData.append("issuedBy", this.issuedBy);
                formData.append("registrationAddress", this.registrationAddress);
                formData.append("address", this.address);
                this.files.forEach((file) => {
                    formData.append(`photos[]`, file);
                });

                this.isLoading = true;

                await axios.post(`${process.env.VUE_APP_MYHOST}/save-winner-data`, formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                });

                this.$router.push({ name: "login" }).catch(() => {});
            } catch (error) {
                alert(error.message);
            } finally {
                this.isLoading = false;
            }
        },

        getUserDataFromToken() {
            try {
                if (!this.$route.query.token) throw new Error();

                const { id } = jwt_decode(this.$route.query.token);
                if (!id) throw new Error();
                if (id !== this.$store.state.userIdFromToken) throw new Error();

                this.userId = id;
            } catch (error) {
                this.$router.push({ name: "login" }).catch(() => {});
            }
        },

        changeUserInfo() {
            this.email = this.$store.state.email.trim();
            this.fio = this.$store.state.fio.trim();
            this.phone = this.$store.state.phone.trim();
        },
    },
    async mounted() {
        await this.getUserData();
        this.getCurrentUserIdFromToken();
        this.getUserDataFromToken();
        this.changeUserInfo();
    },
};
</script>

<style scoped>
.v-form {
    gap: 0;
}

.text {
    margin-bottom: 45px !important;
    max-width: 410px;
    align-self: center;
}

.politic-checkbox,
.confirm-checkbox {
    display: flex;
    align-items: center;
    gap: 14px;
}

.politic,
.confirm,
.fileName {
    text-align: left;
    font-size: 14px;
    color: #868686;
}

.inputs {
    display: flex;
    flex-direction: column;
    gap: 0;
}

@media screen and (max-width: 4000px) and (min-width: 769px) {
    .wrapper-profile {
        max-width: 730px;
        width: 100%;
    }

    .v-form {
        border-radius: 10px;
        padding: 60px;
        gap: 0;
    }

    .v-form h2 {
        font: 700 40px "PT Sans";
    }

    .v-form .description {
        font-size: 18px;
    }

    .v-form .gray-text {
        font-size: 14px;
        color: #868686;
    }

    .checkbox-wrapper {
        display: flex;
        flex-direction: column;
        gap: 16px;
    }

    .politic-checkbox,
    .confirm-checkbox {
        display: flex;
        gap: 14px;
    }

    .checkBox {
        height: 25px !important;
    }

    .politic,
    .confirm {
        text-align: left;
        font-size: 14px;
        color: #868686;
    }

    .politic a {
        color: #868686;
    }
}
</style>
